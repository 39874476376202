/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { unauthNavTo } from '../../../components/navigation'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'

/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // ANCHOR props
  const {
    openModal = true,
    setOpenModal = NOOP,
    participants = [],
    deleteGroups = {},
    deleteSingle = false
  } = props

  // ANCHOR query

  // ANCHOR functions
  const onHide = () => {
    setOpenModal(false)
    deleteGroups.reset()
  }
  const internalOnSave = () => {
    deleteListings()
  }

  /**
   * delete a list of participants.
   */
  const deleteListings = () => {
    deleteGroups.mutateAsync({ groupIds: participants })
  }
  React.useEffect(() => {
    if (deleteGroups.isSuccess) {
      deleteGroups.reset()
      setOpenModal(false)
    }
  }, [deleteGroups, deleteGroups.isSuccess, setOpenModal])
  return (
    <>
      <Modal show={openModal} onHide={onHide} >
        <Modal.Body>
        <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
          <h5 className="m-0 mb-3">
            <img src={InfoIconSvg} className="mr-3" />
            Delete {deleteSingle ? 'group' : participants.length === 1 ? participants.length + ' group' : participants.length + ' groups'} ?
          </h5>

          <p>
            <br />
            This cannot be undone.
          </p>

          {/* buttons */}

          <div className="d-flex justify-content-between mt-5">
            {deleteGroups.isSuccess
              ? (
                  deleteSingle
                    ? (
                <Button
                  onClick={() => {
                    unauthNavTo.viewParticipantList()
                  }}
                >
                  Back to List
                </Button>
                      )
                    : (
                <Button
                  type="button"
                  onClick={onHide}
                  variant="outline-primary"
                >
                  Close
                </Button>
                      )
                )
              : (
              <Button type="button" onClick={onHide} variant="outline-primary">
                Cancel
              </Button>
                )}

            <Button
              type="button"
              onClick={internalOnSave}
              variant="primary"
              disabled={deleteGroups.isSuccess}
            >
              {deleteGroups.isLoading
                ? (
                <Spinner />
                  )
                : (
                <span>Delete</span>
                  )}
            </Button>
          </div>

          {deleteGroups.isSuccess && (
            <Alert variant="success" className="mb-0 mt-3 text-center">
              Delete successful!
            </Alert>
          )}
          {deleteGroups.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'Error deleting accounts. ' +
                deleteGroups.error.message}
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Page
